const config = {
  transition: 'all 0.25s',
}

const colours = [
  '005f87',
  '00af5f',
  '0087af',
  '008700',
  '005faf',
  '005f87',
  'af0000',
  '008700',
  '5f8700',
  '8700af',
  'd75f00',
  'af5f00',
  'd70000',
  'd70087',
]

const randomLinkColour = el => {
  const index = Math.floor(Math.random() * colours.length)
  el.target.style.color = `#${colours[index]}`
  el.target.style.transition = config.transition
}

const resetLinkColour = el => {
  el.target.style.color = 'rgb(55,55,55)'
  el.target.style.transition = config.transition
}

export { randomLinkColour, resetLinkColour }
