import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { Layout, SEO } from '../components'
import styled from 'styled-components'
import media from 'styled-media-query'
import { randomLinkColour, resetLinkColour } from '../functions/randomColour'

const Button = styled.div`
  margin-top: 2rem;
  padding: 0.75rem 1.5rem;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAIAAAACCAYAAABytg0kAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAABZJREFUeNpi2r9//38gYGAEESAAEGAAasgJOgzOKCoAAAAASUVORK5CYII=);
  width: fit-content;
  transition: all 0.1s;
  border: 1px solid transparent;
  a {
    text-decoration: none;
    font-size: 1.25rem;
    font-family: 'IBM Plex Mono', monospace;
    font-weight: medium;
  }
  &::before {
    content: "[";
    font-family: 'mrpx';
    font-size: 1.25rem;
    margin-right: .75rem;
  }
  &:hover {
    border-color: var(--black);
  &::before {
    content: "\\005C";
  }
  }
}
`
const Content = styled.div`
  max-width: 600px;
  text-align: left;
  h3 {
    font-family: 'vg', san-serif;
    font-size: 2rem;
  }
  p {
    transition: all 0.25s;
    ${media.lessThan('medium')`
    font-size: 1rem;
  `}
  }
  .extras {
    h4 {
      font-family: 'IBM Plex Mono', san-serif;
      font-weight: medium;
      font-size: 1.125rem;
      margin-top: 2rem;
      margin-bottom: 0;
      position: relative;
      width: fit-content;
      z-index: 1;
      &::after {
        content: '';
        position: absolute;
        z-index: -2;
        background-color: var(--darkgrey);
        width: calc(100% + 12px);
        height: calc(100% - 12px);
        top: 12px;
        left: -6px;
        transition: all 0.5s;
      }
      &::before {
        content: '';
        position: absolute;
        z-index: -1;
        background-color: var(--yellow);
        width: 0px;
        height: calc(100% - 12px);
        top: 12px;
        left: -6px;
        transition: all 0.5s;
      }
      &:hover {
        cursor: help;
        &::before {
          width: calc(100% + 12px);
        }
      }
    }
    p {
      margin-top: 0;
    }
    .small {
      font-size: 0.875rem;
    }
  }
`
const AboutPage = () => (
  <StaticQuery
    query={graphql`
      query ResumeQuery {
        markdownRemark(frontmatter: { path: { eq: "/resume" } }) {
          frontmatter {
            file {
              publicURL
            }
            path
            title
          }
          html
        }
      }
    `}
    render={data => {
      const about = data.markdownRemark
      return (
        <Layout>
          <SEO title="Home" keywords={[`gatsby`, `application`, `react`]} />
          <Content dangerouslySetInnerHTML={{ __html: about.html }} />
          <Button>
            <a
              onMouseEnter={randomLinkColour}
              onMouseLeave={resetLinkColour}
              href={about.frontmatter.file.publicURL}
              rel="noopener noreferrer"
              target="_blank"
            >
              PDF
            </a>
          </Button>
        </Layout>
      )
    }}
  />
)

export default AboutPage
